<template>
    <b-row>
        <b-col
            v-if="allExpenses.length >= 1 && expenseDetail === true"
            cols="12"
            xl="12"
            lg="12"
            md="12"
            class="d-flex mb-1"
            style="cursor: pointer"
            @click="
                expenseDetail = false
                expenses = {
                    employee: {
                        upload_signature: '',
                    },
                }
            "
        >
            <feather-icon
                style="margin-top: 3px; padding-left: 0px; margin-left: 0px"
                icon="ArrowLeftIcon"
                size="1x"
            />
            <div style="margin-left: 5px">Back</div>
        </b-col>
        <b-col cols="12" xl="9" lg="12" md="12">
            <info-expenses
                v-if="
                    typeof expenses === 'object' &&
                    expenses.hasOwnProperty('employee') &&
                    expenses.employee.hasOwnProperty('id')
                "
                ref="infoExpenses"
                :user-data="expenses"
            />
        </b-col>
        <b-col cols="12" md="12" xl="3" lg="12">
            <last-expenses
                v-if="
                    resetLastExpense === true &&
                    expenses.employee &&
                    series.length > 0 &&
                    optionsLastExpenses.chartOptions.labels.length > 0 &&
                    entries.length > 0 &&
                    expenses.graph &&
                    up
                "
                :user-data="expenses"
                :options="optionsLastExpenses"
                :series="expenses.graph.series"
            />
        </b-col>
        <b-col>
            <b-card v-if="expenses.id">
                <div class="d-flex">
                    <b-button
                        v-if="
                            prevActive &&
                            $store.state.portal.profile.employee.expense &&
                            (this.$route.path === '/forms/expenses/' ||
                                this.$route.path === '/forms/expenses')
                        "
                        v-ripple.400="'rgba(30, 30, 30, 0)'"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle mb-1"
                        @click="previousMonth()"
                    >
                        <feather-icon icon="ChevronLeftIcon" size="1.5x" />
                    </b-button>
                    <b-button
                        v-else-if="
                            $store.state.portal.profile.employee.expense &&
                            (this.$route.path === '/forms/expenses/' ||
                                this.$route.path === '/forms/expenses')
                        "
                        v-ripple.400="'rgba(30, 30, 30, 0)'"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle mb-1"
                        disabled
                        @click="previousMonth()"
                    >
                        <feather-icon icon="ChevronLeftIcon" size="1.5x" />
                    </b-button>
                    <b-button
                        v-if="
                            nextActive &&
                            $store.state.portal.profile.employee.expense &&
                            (this.$route.path === '/forms/expenses/' ||
                                this.$route.path === '/forms/expenses')
                        "
                        v-ripple.400="'rgba(30, 30, 30, 0)'"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle mb-1"
                        @click="nextMonth()"
                    >
                        <feather-icon icon="ChevronRightIcon" size="1.5x" />
                    </b-button>
                    <b-button
                        v-else-if="
                            $store.state.portal.profile.employee.expense &&
                            (this.$route.path === '/forms/expenses/' ||
                                this.$route.path === '/forms/expenses')
                        "
                        v-ripple.400="'rgba(30, 30, 30, 0)'"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle mb-1"
                        disabled
                        @click="nextMonth()"
                    >
                        <feather-icon icon="ChevronRightIcon" size="1.5x" />
                    </b-button>
                    <div
                        v-if="
                            $store.state.portal.profile.employee.expense &&
                            (this.$route.path === '/forms/expenses/' ||
                                this.$route.path === '/forms/expenses')
                        "
                    >
                        <b-button
                            id="monthDate"
                            v-ripple.400="'rgba(30, 30, 30, 0)'"
                            style="margin-top: -5px"
                            size="lg"
                            variant="flat-secondary"
                            class="btn-icon mb-1 ml-10"
                            @click="
                                monthPicker =
                                    monthPicker === true ? false : true
                            "
                        >
                            {{ expenses.date | monthYearFormat }}
                            <feather-icon icon="ChevronDownIcon" size="1x" />
                        </b-button>
                        <div
                            v-if="monthPicker && expenses.date"
                            style="position: absolute"
                            class="mp-index"
                        >
                            <Calendar
                                v-if="monthPicker && expenses.date"
                                v-model="month"
                                view="month"
                                date-format="mm/yy"
                                :inline="true"
                                :min-date="
                                    new Date(
                                        new Date(expenses.months).setMonth(
                                            new Date(expenses.months).getMonth()
                                        )
                                    )
                                "
                                :max-date="
                                    new Date(
                                        new Date().setMonth(
                                            new Date().getMonth() + 1
                                        )
                                    )
                                "
                            />
                            <!-- Form Actions -->
                            <div
                                class="d-flex custom-month-picker"
                                style="flex-direction: row-reverse"
                            >
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    class="margin-left-10"
                                    variant="primary"
                                    size="sm"
                                    @click="showDate(month)"
                                >
                                    Apply
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                    variant="outline-secondary"
                                    size="sm"
                                    @click="
                                        monthPicker =
                                            monthPicker === true ? false : true
                                    "
                                >
                                    Cancel
                                </b-button>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="
                            !$store.state.portal.profile.employee.expense ||
                            (this.$route.path !== '/forms/expenses/' &&
                                this.$route.path !== '/forms/expenses')
                        "
                        class="ml-1 mt-10 fs-16 mb-1"
                    >
                        <span
                            style="
                                font-size: 1.25rem;
                                font-weight: 500;
                                color: #82868b;
                            "
                            >{{ expenses.date | monthYearFormat }}</span
                        >
                    </div>
                    <div class="mr-auto mt-10">
                        <b-badge
                            v-if="
                                expenses.status &&
                                Object.keys(expenses.status).length !== 0
                            "
                            class="ml-1"
                            :variant="'light-' + setStatusColor()"
                        >
                            {{ expenses.status.description }}
                        </b-badge>
                    </div>
                    <div>
                        <b-dropdown
                            v-if="
                                $route.path !== '/forms/expenses' &&
                                $route.path !== '/forms/expenses/'
                            "
                            id="dropdown-7"
                            v-ripple.400="'rgba(255, 255, 255, 0)'"
                            class="mr-1"
                            text="Actions"
                            variant="secondary"
                        >
                            <b-dropdown-item>Select action:</b-dropdown-item>
                            <b-dropdown-item
                                :disabled="!approve"
                                @click="save(3)"
                                ><feather-icon
                                    class="text-success mr-right-10"
                                    icon="CheckIcon"
                                />Approve</b-dropdown-item
                            >
                            <b-dropdown-item
                                :disabled="!decline"
                                @click="save(4)"
                                ><feather-icon
                                    class="text-danger mr-right-10"
                                    icon="XIcon"
                                />Decline</b-dropdown-item
                            >
                            <b-dropdown-item
                                :disabled="!nudge"
                                @click="nudgeAction()"
                                ><feather-icon
                                    class="text-warning mr-right-10"
                                    icon="SendIcon"
                                />Nudge</b-dropdown-item
                            >
                            <b-dropdown-item
                                :disabled="!reopen"
                                @click="save(1)"
                                ><feather-icon
                                    class="text-info mr-right-10"
                                    icon="RotateCwIcon"
                                />Reopen</b-dropdown-item
                            >
                            <b-dropdown-item
                                v-b-modal.change-request
                                :disabled="!changeRequest"
                                ><feather-icon
                                    class="text-secondary mr-right-10"
                                    icon="Edit3Icon"
                                />Change request</b-dropdown-item
                            >
                        </b-dropdown>
                        <b-dropdown
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="flat-secondary"
                            text="Export"
                            :disabled="
                                expenses.status.description === 'Closed' &&
                                expenses.is_empty === true
                            "
                        >
                            <template #button-content>
                                <feather-icon icon="DownloadIcon" size="1.5x" />
                            </template>
                            <b-dropdown-item @click="download()">
                                Excel
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="download((exportTo = 'pdf'))"
                            >
                                PDF
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <b-modal
                    id="change-request"
                    centered
                    title="Change request"
                    cancel
                    cancel-variant="outline-secondary"
                    ok-title="Submit request"
                    size="lg"
                    @ok="submitRequest()"
                >
                    <b-card-text>
                        Incorrect or missing information? Submit a change
                        request to the employee.
                    </b-card-text>
                    <b-card-text>
                        <b-form-group
                            class="mt-1"
                            label="Requested by"
                            label-for="requestedBy"
                        >
                            <b-form-input
                                id="requestedBy"
                                disabled
                                :value="
                                    $store.state.portal.profile.first_name +
                                    ' ' +
                                    $store.state.portal.profile.last_name
                                "
                            />
                        </b-form-group>
                    </b-card-text>
                    <b-card-text>
                        <label for="reason-for-change">Comments</label>
                        <b-form-textarea
                            id="reason-for-change"
                            v-model="message"
                            placeholder="Please use this space to explain reason for change, as well as additional information."
                            rows="3"
                        />
                    </b-card-text>
                </b-modal>
                <div>
                    <app-collapse
                        v-if="request"
                        class="shadow mb-1 bg-white rounded"
                        style="
                            padding-bottom: 10px;
                            z-index: 1;
                            overflow: scroll;
                        "
                    >
                        <app-collapse-item
                            ref="tlMessages"
                            :is-visible="true"
                            :title="
                                isVisible
                                    ? ' ' + 'Hide change request'
                                    : ' ' + 'View change request'
                            "
                            style="z-index: 1"
                            @visible="isVisible = $event"
                        >
                            <app-timeline style="z-index: 1">
                                <app-timeline-item
                                    v-for="item in messages"
                                    :key="item.id"
                                    :title="
                                        item.created_by.first_name +
                                        ' ' +
                                        item.created_by.last_name +
                                        ':'
                                    "
                                    :subtitle="item.content"
                                    :time="item.created_at"
                                    variant="primary"
                                    class="mt-1"
                                    style="z-index: 1"
                                />
                            </app-timeline>
                            <!-- <label v-if="$route.path !== '/forms/mileage' && $route.path !== '/forms/mileage/'" class="mt-1" for="textarea-default">Request changes</label> -->
                            <!-- <b-form-textarea
                  v-if="$route.path !== '/forms/mileage' && $route.path !== '/forms/mileage/'"
                  id="textarea-default"
                  placeholder="Describe here what needs changes"
                  rows="3"
                  v-model="message"
                /> -->
                            <!-- <b-button
                  v-if="$route.path !== '/forms/mileage' && $route.path !== '/forms/mileage/'"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="mt-1 ml-1 b-right"
                  @click="cancelSend()"
                >
                  <span class="align-middle">Cancel</span>
                </b-button>
                <b-button
                  v-if="$route.path !== '/forms/mileage' && $route.path !== '/forms/mileage/'"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-success"
                  class="mt-1 b-right"
                  @click="send()"
                >
                  <feather-icon
                    icon="SendIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Send</span>
                </b-button> -->
                        </app-collapse-item>
                    </app-collapse>
                </div>
                <expenses-table
                    v-if="
                        entries.length > 0 &&
                        dateMaxMin.minDate !== '' &&
                        rerendereExpensesTable
                    "
                    ref="refExpensesTable"
                    :value="entries"
                    :date-max-min="dateMaxMin"
                    :action="action"
                    :expenses="expenses"
                    @real-value="getRValue($event)"
                    @updateExpenses="updateExpenses()"
                    @deleteExpenses="updateExpenses"
                    @delete-field="deleteField"
                />
                <b-card-text
                    v-if="
                        (expenses.status.description === 'Open' ||
                            expenses.status.description === 'Closed') &&
                        entries.length <= 1
                    "
                    class="text-primary ml-1 font-weight-bolder"
                >
                    If you don't have expense to declare this month, please
                    select the following option and then click on "Submit":
                    <b-form-checkbox
                        v-model="expenses.is_empty"
                        class="mt-10 mb-1"
                        :disabled="
                            expenses.status.description === 'Closed' ||
                            expenses.condition
                        "
                    >
                        I confirm that I have no expense to declare for the
                        current cycle.
                    </b-form-checkbox>
                </b-card-text>
                <b-card-text
                    v-if="expenses.is_empty === false"
                    class="ml-1 font-weight-bolder"
                    style="font-size: 12px; color: #5e5873"
                >
                    I accept<span class="text-danger">*</span>:
                    <b-form-checkbox
                        v-model="expenses.condition"
                        class="mt-10 mb-2"
                        :disabled="
                            expenses.status.description !== 'Open' ||
                            expenses.is_empty
                        "
                    >
                        <span style="font-size: 12px"
                            >Employee Portal uses your signature exclusively for
                            the purpose of signing expense reports, timesheets
                            and mileage reports, if applicable.</span
                        >
                    </b-form-checkbox>
                </b-card-text>
                <b-button
                    v-if="
                        expenses.status &&
                        expenses.is_empty === false &&
                        (expenses.status.description === 'Open' ||
                            expenses.status.description === 'Pending changes')
                    "
                    v-ripple.400="'rgba(0, 0,	0, 0)'"
                    variant="outline-primary"
                    @click="
                        add(
                            {
                                item: entries[0],
                            },
                            true
                        )
                    "
                >
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="align-middle">Add expense</span>
                </b-button>
                <div style="float: right">
                    <div class="demo-inline-spacing">
                        <b-button
                            v-if="
                                expenses.status &&
                                expenses.is_empty === false &&
                                (expenses.status.description === 'Open' ||
                                    expenses.status.description ===
                                        'Pending changes')
                            "
                            v-ripple.400="'rgba(0, 0,	0, 0)'"
                            variant="flat-primary"
                            @click="saveExpenses()"
                        >
                            <feather-icon icon="SaveIcon" class="mr-50" />
                            <span class="align-middle">Save</span>
                        </b-button>
                        <b-button
                            v-if="
                                expenses.status &&
                                (expenses.status.description === 'Open' ||
                                    expenses.status.description ===
                                        'Pending changes')
                            "
                            v-ripple.400="'rgba(0, 0,	0, 0)'"
                            variant="primary"
                            @click="submit()"
                        >
                            Submit
                        </b-button>
                        <b-modal
                            id="model-signature"
                            centered
                            title="Signature"
                            ok-only
                            ok-title="Save"
                            size="lg"
                            @ok="signatureSave"
                        >
                            <b-card-text>
                                <h5>
                                    Sign your expense form before submitting it
                                    for approval. Please select one of these
                                    options:
                                </h5>
                                <b-tabs
                                    v-model="tabIndex"
                                    content-class="mt-2"
                                    class="mt-1"
                                    justified
                                >
                                    <b-tab
                                        active
                                        title="Create"
                                        style="min-width: 100%"
                                    >
                                        <b-card-text>
                                            <h4>Create digital signature</h4>

                                            <p>
                                                When creating a digital
                                                signature your expense forms
                                                will be automatically signed
                                                monthly.
                                            </p>

                                            <p>
                                                It's easy, fast and eliminates
                                                paper waste!
                                            </p>

                                            Use your mouse to draw the
                                            signature. Click “Save” when you
                                            make a perfect one.
                                        </b-card-text>
                                        <vueSignature
                                            ref="signature"
                                            :sig-option="option"
                                            w="760px"
                                            h="300px"
                                            style="border: 1px solid black"
                                            :disabled="disabled"
                                        />
                                        <b-button
                                            v-ripple.400="
                                                'rgba(186, 191, 199, 0.15)'
                                            "
                                            class="mt-1"
                                            style="float: right"
                                            variant="flat-secondary"
                                            @click="clear"
                                        >
                                            <feather-icon
                                                icon="RotateCwIcon"
                                                class="mr-right-10"
                                            />
                                            Clear
                                        </b-button>
                                    </b-tab>
                                    <b-tab title="Upload">
                                        <b-card-text>
                                            <h4>Upload a signature</h4>

                                            <p>
                                                If you already have an image of
                                                your signature, you can also
                                                upload it and use it.
                                            </p>

                                            <p>
                                                You no longer have to worry
                                                about signing expense form every
                                                time you submit it for approval.
                                            </p>

                                            <p>Once and never again!</p>
                                        </b-card-text>
                                        <b-form-file
                                            v-if="expenses.employee"
                                            id="input-upload-signature"
                                            v-model="upload_signature"
                                            class="mt-1"
                                            placeholder="Choose a file or drop it here..."
                                            drop-placeholder="Drop file here..."
                                        />
                                        <p
                                            v-if="
                                                $store.state.portal.profile
                                                    .employee !== 'undefined' &&
                                                $store.state.portal.profile
                                                    .employee.upload_signature
                                            "
                                            style="font-size: 10px"
                                        >
                                            Selected file:
                                            <strong
                                                ><a
                                                    :href="
                                                        $store.state.portal
                                                            .profile.employee
                                                            .upload_signature
                                                            ? $store.state
                                                                  .portal
                                                                  .profile
                                                                  .employee
                                                                  .upload_signature
                                                            : ''
                                                    "
                                                    target="_blank"
                                                    >My Signature</a
                                                ></strong
                                            >
                                        </p>
                                    </b-tab>
                                    <b-tab title="Manual">
                                        <b-card-text>
                                            <h4>Manual</h4>

                                            <p>
                                                You can also sign expense form
                                                manually.
                                            </p>

                                            <p>
                                                To do this, make sure you have
                                                completed all missing entries,
                                                download your expense file, sign
                                                it, scan it and finally send it
                                                back by clicking “Upload it”.
                                            </p>
                                        </b-card-text>
                                        <div style="text-align: center">
                                            <b-button
                                                v-ripple.400="
                                                    'rgba(24,	91,	137, 0.15)'
                                                "
                                                variant="outline-primary"
                                                @click="
                                                    download((exportTo = 'pdf'))
                                                "
                                            >
                                                <feather-icon
                                                    icon="DownloadIcon"
                                                    class="mr-50"
                                                />
                                                <span class="align-middle"
                                                    >Download</span
                                                >
                                            </b-button>
                                        </div>
                                        <b-card-text class="mt-1">
                                            <p>
                                                Already signed? Upload it below:
                                            </p>
                                        </b-card-text>
                                        <b-form-file
                                            v-model="file"
                                            placeholder="Choose a file or drop it here..."
                                            drop-placeholder="Drop file here..."
                                        />
                                        <p style="font-size: 10px">
                                            Selected file:
                                            <strong>{{
                                                file ? file.name : ""
                                            }}</strong>
                                        </p>
                                        <b-card-text class="text-warning mt-1">
                                            Keep in mind that by choosing this
                                            option, you must to repeat this
                                            signing process every time you
                                            submit a expense form.
                                        </b-card-text>
                                    </b-tab>
                                </b-tabs>
                            </b-card-text>
                        </b-modal>
                        <b-button
                            v-if="
                                expenses.status &&
                                (expenses.status.description === 'Open' ||
                                    expenses.status.description ===
                                        'Pending changes') &&
                                !$store.state.portal.profile.employee
                                    .signature &&
                                !$store.state.portal.profile.employee
                                    .upload_signature
                            "
                            v-ripple.400="'rgba(24,	91,	137, 0.15)'"
                            v-b-modal.model-signature
                            variant="outline-primary"
                        >
                            <feather-icon icon="Edit3Icon" class="mr-50" />
                            <span class="align-middle">Signature</span>
                        </b-button>
                    </div>
                </div>
            </b-card>
            <div v-else-if="notExpenses" />
            <b-card v-else>
                <div class="custom-search d-flex">
                    <div class="d-flex mr-auto">
                        <b-card-text style="font-size: 16px">
                            Click on date to check or record your expense
                        </b-card-text>
                    </div>
                    <div class="d-flex align-items-center">
                        <b-form-group>
                            <b-form-input
                                v-model="searchTerm"
                                placeholder="Search"
                                type="text"
                                class="d-inline-block"
                            />
                        </b-form-group>
                    </div>
                </div>
                <vue-good-table
                    v-if="resetLastExpense"
                    :columns="columns"
                    :rows="allExpenses"
                    :search-options="{
                        enabled: true,
                        externalQuery: searchTerm,
                    }"
                >
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'status'">
                            <b-badge
                                :variant="
                                    statusVariant(props.row.status.description)
                                "
                            >
                                {{ props.row.status.description }}
                            </b-badge>
                        </span>
                        <span
                            v-else-if="props.column.field === 'employee.name'"
                            style="cursor: pointer"
                            @click="
                                $router.push(
                                    '/forms/expenses/' +
                                        props.row.employee.id +
                                        '/' +
                                        props.row.date.split('-')[1] +
                                        '/' +
                                        props.row.date.split('-')[0]
                                )
                            "
                        >
                            <b-avatar
                                class="mr-right-10"
                                size="36px"
                                :src="props.row.employee.avatar"
                            />
                            {{ props.row.employee.name }}
                        </span>
                        <span
                            v-else-if="
                                props.column.field ===
                                'employee.reports_to.name'
                            "
                            class="d-flex"
                        >
                            <b-avatar
                                v-if="props.row.employee.reports_to"
                                class="mr-right-10"
                                size="36px"
                                :src="props.row.employee.reports_to.avatar"
                            />
                            <div
                                v-if="props.row.employee.reports_to"
                                class="name-vt-align"
                            >
                                {{ props.row.employee.reports_to.name }}
                            </div>
                        </span>
                        <span
                            v-else-if="props.column.field === 'date'"
                            v-b-tooltip.hover.right="
                                'Click to check or record!'
                            "
                            style="cursor: pointer"
                            @click="
                                showDetail(
                                    props.formattedRow[props.column.field]
                                )
                            "
                        >
                            {{
                                props.formattedRow[props.column.field]
                                    | monthYearFormat
                            }}
                        </span>
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>
                </vue-good-table>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BModal,
    BTabs,
    BTab,
    BFormFile,
    VBTooltip,
    BAvatar,
    BFormCheckbox,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import InfoExpenses from "@/components/InfoExpenses.vue"
import LastExpenses from "@/components/LastExpenses.vue"
import ExpensesTable from "@/components/ExpensesTable.vue"
import Expenses from "@/api/services/Expenses"
import { MonthPicker } from "vue-month-picker"
import Ripple from "vue-ripple-directive"
import moment from "moment"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"
import { $themeColors } from "@themeConfig"
import vueSignature from "vue-signature"
import Auth from "@/api/services/Auth"
import { VueGoodTable } from "vue-good-table"
import Calendar from "primevue/calendar"
import { SettingsColors } from "@/helpers/settingsColors"
import "primevue/resources/themes/bootstrap4-light-blue/theme.css"
import "primevue/resources/themes/saga-blue/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import { WEEKDAYS, FORMAT_SIGNATURES_ACCEPETD } from "@/store/constants"

const $earningsStrokeColor2 = "#28c76f66"
const $earningsStrokeColor3 = "#28c76f33"

export default {
    name: "Expenses",
    components: {
        BAvatar,
        ToastificationContent,
        InfoExpenses,
        LastExpenses,
        ExpensesTable,
        BRow,
        BCol,
        BCard,
        MonthPicker,
        BButton,
        BBadge,
        BDropdown,
        BDropdownItem,
        BDropdownDivider,
        BCardText,
        BFormInput,
        BFormGroup,
        BFormTextarea,
        BModal,
        AppCollapse,
        AppCollapseItem,
        AppTimeline,
        AppTimelineItem,
        BTabs,
        BTab,
        vueSignature,
        BFormFile,
        Calendar,
        VueGoodTable,
        BFormCheckbox,
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
    },
    data() {
        return {
            // selectedExpense: false,
            s: false,
            up: true,
            columns: [
                {
                    label: "Date",
                    field: "date",
                    tdClass: "height-50",
                },
                {
                    label: "Status",
                    field: "status",
                    tdClass: "height-50",
                },
                {
                    label: "Reports to",
                    field: "employee.reports_to.name",
                },
                {
                    label: "Total",
                    field: "amount",
                    type: "decimal",
                    formatFn: this.formatEuro,
                },
            ],
            searchTerm: "",
            expenseDetail: false,
            notExpenses: true,
            month: "",
            expenses: {
                employee: {
                    upload_signature: "",
                },
            },
            entries: [],
            dateMaxMin: {
                minDate: "",
                maxDate: "",
                dateFormat: "d-m-Y",
            },
            prevActive: true,
            nextActive: true,
            allExpenses: [],
            monthPicker: false,
            approve: false,
            decline: false,
            reopen: false,
            nudge: false,
            changeRequest: false,
            message: "",
            myValue: "",
            action: true,
            request: false,
            messages: [],
            isVisible: true,
            rerendereExpensesTable: true,
            optionsLastExpenses: {
                chartOptions: {
                    chart: {
                        type: "donut",
                        toolbar: {
                            show: false,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    legend: { show: false },
                    labels: [],
                    stroke: { width: 0 },
                    colors: [
                        $earningsStrokeColor2,
                        $earningsStrokeColor3,
                        $themeColors.success,
                    ],
                    grid: {
                        padding: {
                            right: -20,
                            bottom: -8,
                            left: -20,
                        },
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        offsetY: 15,
                                    },
                                    value: {
                                        offsetY: -15,
                                        formatter(val) {
                                            // eslint-disable-next-line radix
                                            return `${parseInt(val)}%`
                                        },
                                    },
                                    total: {
                                        show: true,
                                        offsetY: 15,
                                        label: "Expenses",
                                        formatter: () => "100%",
                                    },
                                },
                            },
                        },
                    },
                    responsive: [
                        {
                            breakpoint: 1670,
                            options: {
                                chart: {
                                    height: 120,
                                },
                            },
                        },
                        {
                            breakpoint: 1559,
                            options: {
                                chart: {
                                    height: 110,
                                },
                            },
                        },
                        {
                            breakpoint: 1509,
                            options: {
                                chart: {
                                    height: 100,
                                },
                            },
                        },
                        {
                            breakpoint: 1440,
                            options: {
                                chart: {
                                    height: 90,
                                },
                            },
                        },
                        {
                            breakpoint: 1360,
                            options: {
                                chart: {
                                    height: 80,
                                },
                            },
                        },
                        {
                            breakpoint: 1281,
                            options: {
                                chart: {
                                    height: 60,
                                },
                            },
                        },
                        {
                            breakpoint: 1200,
                            options: {
                                chart: {
                                    height: 145,
                                },
                            },
                        },
                        {
                            breakpoint: 1045,
                            options: {
                                chart: {
                                    height: 145,
                                },
                            },
                        },
                        {
                            breakpoint: 992,
                            options: {
                                chart: {
                                    height: 120,
                                },
                            },
                        },
                    ],
                },
            },
            series: [],
            tabIndex: 0,
            option: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgb(255,255,255)",
            },
            disabled: false,
            upload_signature: "",
            file: [],
            resetLastExpense: true,
        }
    },
    watch: {
        $route(to, from) {
            this.expenses = {
                graph: {
                    series: [],
                },
                employee: {
                    upload_signature: "",
                },
            }
            this.entries = []
            this.dateMaxMin = {
                minDate: "",
                maxDate: "",
                dateFormat: "d-m-Y",
            }
            this.prevActive = true
            this.nextActive = true
            this.allExpenses = []
            this.monthPicker = false
            this.approve = false
            this.decline = false
            this.reopen = false
            this.nudge = false
            this.changeRequest = false
            this.message = ""
            this.myValue = ""
            this.action = true
            this.request = false
            this.messages = []
            this.isVisible = true
            this.rerendereExpensesTable = true
            ;(this.columns = [
                {
                    label: "Date",
                    field: "date",
                    tdClass: "height-50",
                },
                {
                    label: "Status",
                    field: "status",
                    tdClass: "height-50",
                },
                {
                    label: "Reports to",
                    field: "employee.reports_to.name",
                },
                {
                    label: "Total",
                    field: "amount",
                    type: "decimal",
                    formatFn: this.formatEuro,
                },
            ]),
                (this.optionsLastExpenses = {
                    // chartOptions: {
                    //   chart: {
                    //     type: 'donut',
                    //     toolbar: {
                    //       show: false,
                    //     },
                    //   },
                    //   dataLabels: {
                    //     enabled: false,
                    //   },
                    //   legend: { show: false },
                    //   comparedResult: [2, -3, 8],
                    //   labels: [],
                    //   stroke: { width: 0 },
                    //   colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
                    //   grid: {
                    //     padding: {
                    //       right: -20,
                    //       bottom: -8,
                    //       left: -20,
                    //     },
                    //   },
                    //   plotOptions: {
                    //     pie: {
                    //       startAngle: -10,
                    //       donut: {
                    //         labels: {
                    //           show: true,
                    //           name: {
                    //             offsetY: 15,
                    //           },
                    //           value: {
                    //             offsetY: -15,
                    //             formatter(val) {
                    //               // eslint-disable-next-line radix
                    //               return `${parseInt(val)}%`
                    //             },
                    //           },
                    //           total: {
                    //             show: true,
                    //             offsetY: 15,
                    //             label: 'Expenses',
                    //             formatter: () => {
                    //               return '100%'
                    //             },
                    //           },
                    //         },
                    //       },
                    //     },
                    //   },
                    //   responsive: [
                    //     {
                    //       breakpoint: 1325,
                    //       options: {
                    //         chart: {
                    //           height: 100,
                    //         },
                    //       },
                    //     },
                    //     {
                    //       breakpoint: 1200,
                    //       options: {
                    //         chart: {
                    //           height: 120,
                    //         },
                    //       },
                    //     },
                    //     {
                    //       breakpoint: 1045,
                    //       options: {
                    //         chart: {
                    //           height: 100,
                    //         },
                    //       },
                    //     },
                    //     {
                    //       breakpoint: 992,
                    //       options: {
                    //         chart: {
                    //           height: 120,
                    //         },
                    //       },
                    //     },
                    //   ],
                    // },
                    chartOptions: {
                        chart: {
                            type: "donut",
                        },
                        responsive: [
                            {
                                breakpoint: 480,
                                options: {
                                    chart: {
                                        width: 200,
                                    },
                                    legend: {
                                        position: "bottom",
                                    },
                                },
                            },
                        ],
                    },
                })
            this.series = []
            this.loadData()
        },
        messages(value) {
            if (value.length > 0) {
                this.request = true
            } else {
                this.request = false
            }
        },
        expenses(value) {
            this.$loading(true)
            if (value.date) {
                if (value.date === value.months) {
                    this.prevActive = false
                } else {
                    this.prevActive = true
                }
                if (
                    value.date.slice(0, -3) ===
                    moment(
                        new Date(
                            new Date().setMonth(new Date().getMonth() + 1)
                        ),
                        "YYYY-MM-DD"
                    )
                        .format("YYYY-MM-DD")
                        .slice(0, -3)
                ) {
                    this.nextActive = false
                } else {
                    this.nextActive = true
                }
                if (
                    this.$route.path !== "/forms/expenses/" &&
                    this.$route.path !== "/forms/expenses"
                ) {
                    let approve = true
                    let decline = true
                    let reopen = true
                    let nudge = true
                    let changeRequest = true
                    if (value.length === 0) {
                        approve = false
                        decline = false
                        reopen = false
                        nudge = false
                        changeRequest = false
                    }
                    if (
                        value.status.description === "Open" ||
                        value.status.description === "Closed" ||
                        value.status.description === "Pending changes"
                    ) {
                        approve = false
                        decline = false
                        reopen = false
                        changeRequest = false
                    }
                    if (value.status.description === "Approved") {
                        approve = false
                        nudge = false
                    }
                    if (value.status.description === "Declined") {
                        decline = false
                        nudge = false
                    }
                    if (
                        value.status.description === "Closed" ||
                        value.status.description === "Approved" ||
                        value.status.description === "Declined" ||
                        value.status.description === "Pending approval"
                    ) {
                        nudge = false
                    }
                    this.approve = approve
                    this.decline = decline
                    this.reopen = reopen
                    this.nudge = nudge
                    this.changeRequest = changeRequest
                    if (!this.s) {
                        this.$loading(false)
                    }
                }
                if (!this.s) {
                    this.$loading(false)
                }
            }
            if (value.hasOwnProperty("graph")) {
                this.series = value.graph.series
                this.optionsLastExpenses.chartOptions.labels =
                    value.graph.options
                this.resetLastExpense = false
                Expenses.myExpenses(
                    this.$store.state.portal.profile.employee.id
                ).then((response) => {
                    this.allExpenses = response?.data?.results || []
                    if (!this.s) {
                        this.$loading(false)
                    }
                })
                if (this.s) {
                    this.$loading(true)
                }
                this.$nextTick(() => {
                    this.resetLastExpense = true
                })
            }
            if (!this.s) {
                this.$loading(false)
            }
        },
        entries: {
            handler(after, before) {
                this.sumExpenses(after)
            },
            deep: true,
        },
    },
    created() {
        this.loadData()
    },
    methods: {
        getRValue(value) {
            this.entries.forEach(e => {
                if (value === e.id) {
                    e.is_real = !e.is_real
                }
            })
        },
        sumExpenses(value) {
            let amount = 0
            const categories = []
            value?.forEach((item) => {
                if (item.amount) {
                    amount += parseFloat(item.amount)
                }
                if (!categories.includes(item.category)) {
                    categories.push(item.category)
                }
            })
            if (this.expenses.amount !== amount) {
                this.expenses.amount = amount
                this.expenses.graph.series = []
                this.series = []
                const options = []
                for (let ix = 0; ix < categories.length; ix++) {
                    let total = 0
                    for (let i = 0; i < value.length; i++) {
                        if (value[i].category === categories[ix]) {
                            total += parseFloat(value[i].amount)
                        }
                    }
                    this.expenses.graph.series.push(
                        Math.round((total * 100) / amount)
                    )
                    this.series.push(Math.round((total * 100) / amount))
                    options.push(this.getCategoryDescription(categories[ix]))
                }
                let max = 0
                let ind = 0
                for (let x = 0; x < this.expenses.graph.series.length; x++) {
                    if (this.expenses.graph.series[x] > max) {
                        max = this.expenses.graph.series[x]
                        ind = x
                    }
                }
                this.optionsLastExpenses.chartOptions.labels = options
                this.expenses.graph.maximum.label = this.getCategoryDescription(
                    options[ind]
                )
                this.expenses.graph.maximum.value = max
                this.up = false
            }
            this.$nextTick(() => {
                this.up = true
            })
        },
        getCategoryDescription(id) {
            if (!this.$refs?.refExpensesTable?.categories?.length) return
            for (
                let ix = 0;
                ix < this.$refs.refExpensesTable.categories.length;
                ix++
            ) {
                if (this.$refs.refExpensesTable.categories[ix].value === id) {
                    return this.$refs.refExpensesTable.categories[ix].text
                }
            }
        },
        statusVariant(status) {
            return SettingsColors.setupVariant(status)
        },
        formatEuro(value) {
            return `${value.toFixed(2)} €`
        },
        clear() {
            const _this = this
            _this.$refs.signature.clear()
        },
        saveSignature() {
            const formData = new FormData()
            const canvas = document.getElementById(this.$refs.signature.uid)
            canvas.toBlob((blob) => {
                formData.append("upload_signature", blob, "filename.png")
                formData.append(
                    "id",
                    this.$store.state.portal.profile.employee.id
                )
                formData.append(
                    "is_active",
                    this.$store.state.portal.profile.employee.is_active
                )
                formData.append(
                    "expense",
                    this.$store.state.portal.profile.employee.expense
                )
                formData.append(
                    "timesheets",
                    this.$store.state.portal.profile.employee.timesheets
                )
                formData.append(
                    "mileage",
                    this.$store.state.portal.profile.employee.mileage
                )
                formData.append(
                    "name",
                    this.$store.state.portal.profile.employee.name
                )
                Auth.employeePut(
                    {
                        id: this.$store.state.portal.profile.employee.id,
                        is_active:
                            this.$store.state.portal.profile.employee.is_active,
                        expense:
                            this.$store.state.portal.profile.employee.expense,
                        timesheets:
                            this.$store.state.portal.profile.employee
                                .timesheets,
                        mileage:
                            this.$store.state.portal.profile.employee.mileage,
                        name: this.$store.state.portal.profile.employee.name,
                    },
                    formData
                ).then((response) => {
                    this.$store.state.portal.profile.employee.upload_signature =
                        response.data.upload_signature
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Success",
                                icon: "CheckCircleIcon",
                                text: "Your signature has been successfully saved! 🥳",
                                variant: "success",
                            },
                        },
                        {
                            position: "top-center",
                        }
                    )
                })
            })
        },
        signatureSave() {
            this.$loading(true)
            switch (this.tabIndex) {
                case 0:
                    // criação da assinatura eletrônica
                    this.saveSignature()
                    break
                case 1:
                    // upload da assinatura
                    if (
                        FORMAT_SIGNATURES_ACCEPETD.some(e => this.upload_signature.name.includes(e))
                    ) {
                        const formData = new FormData()
                        formData.append(
                            "upload_signature",
                            this.upload_signature
                        )
                        formData.append(
                            "id",
                            this.$store.state.portal.profile.employee.id
                        )
                        formData.append(
                            "is_active",
                            this.$store.state.portal.profile.employee.is_active
                        )
                        formData.append(
                            "expense",
                            this.$store.state.portal.profile.employee.expense
                        )
                        formData.append(
                            "timesheets",
                            this.$store.state.portal.profile.employee.timesheets
                        )
                        formData.append(
                            "mileage",
                            this.$store.state.portal.profile.employee.mileage
                        )
                        formData.append(
                            "name",
                            this.$store.state.portal.profile.employee.name
                        )
                        Auth.employeePut(
                            {
                                id: this.$store.state.portal.profile.employee
                                    .id,
                                is_active:
                                    this.$store.state.portal.profile.employee
                                        .is_active,
                                expense:
                                    this.$store.state.portal.profile.employee
                                        .expense,
                                timesheets:
                                    this.$store.state.portal.profile.employee
                                        .timesheets,
                                mileage:
                                    this.$store.state.portal.profile.employee
                                        .mileage,
                                name: this.$store.state.portal.profile.employee
                                    .name,
                            },
                            formData
                        ).then((response) => {
                            this.$store.state.portal.profile.employee.upload_signature =
                                response.data.upload_signature
                            this.$toast(
                                {
                                    component: ToastificationContent,
                                    props: {
                                        title: "Success",
                                        icon: "CheckCircleIcon",
                                        text: "Your signature has been successfully saved! 🥳",
                                        variant: "success",
                                    },
                                },
                                {
                                    position: "top-center",
                                }
                            )
                        })
                    } else {
                        this.$toast(
                            {
                                component: ToastificationContent,
                                props: {
                                    title: "Error: File format is not supported! 🤚",
                                    icon: "XIcon",
                                    text: "You can only upload png, jpg or jpeg files.",
                                    variant: "danger",
                                },
                            },
                            {
                                position: "bottom-center",
                                timeout: 8000,
                            }
                        )
                        this.$loading(false)
                    }
                    break
                default:
                    // upload do file para attachment
                    this.save(this.expenses.status.id, true)
                    break
            }
        },
        deleteField() {
            this.$loading(true)
            Expenses.getExpense(this.expenses.id).then((response) => {
                this.expenses = {
                    employee: {
                        upload_signature: "",
                    },
                }
                this.expenses = response.data
                this.sumExpenses(this.entries)
                this.$loading(false)
            })
        },
        updateExpenses() {
            this.$loading(true)
            this.saveExpenses(false, true, false)
            this.$loading(false)
        },
        submit() {
            this.s = true
            this.$loading(true)
            if (this.expenses.is_empty) {
                Expenses.putExpenses({
                    id: this.expenses.id,
                    is_empty: this.expenses.is_empty,
                    condition: this.expenses.condition,
                    date: this.expenses.date,
                    status: 5,
                }).then((response) => {
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Success",
                                icon: "CheckCircleIcon",
                                text: "Your mileage has been successfully submitted! 🥳",
                                variant: "success",
                            },
                        },
                        {
                            position: "top-center",
                        }
                    )
                    this.loadData()
                })
            } else {
                this.saveExpenses(false, false, true)
                this.$loading(true)
                setTimeout(() => {
                    let valid = true
                    let type = ""
                    if (
                        !this.$refs.infoExpenses.userData.employee.signature &&
                        !this.$refs.infoExpenses.userData.employee
                            .upload_signature
                    ) {
                        valid = false
                        type = "signature"
                    }
                    if (!this.expenses.condition) {
                        valid = false
                        type = "condition"
                    }
                    this.entries.forEach(async (item) => {
                        if (
                            !item.date ||
                            !item.category ||
                            !item.description ||
                            !item.invoice ||
                            !item.amount
                            // !item.is_real
                        ) {
                            valid = false
                        }
                    })
                    if (valid === true) {
                        Expenses.putExpenses({
                            id: this.expenses.id,
                            date: this.expenses.date,
                            is_empty: this.expenses.is_empty,
                            condition: this.expenses.condition,
                            status: 2,
                        })
                            .then(() => {
                                this.$loading(true)
                                const dt = this.expenses.date.split("-")
                                this.$loading(true)
                                let employee_id = ""
                                if (this.$route.params.id) {
                                    employee_id = this.$route.params.id
                                }
                                Expenses.getExpenses(
                                    dt[0],
                                    dt[1],
                                    employee_id
                                ).then((response) => {
                                    this.$loading(true)
                                    this.expenses = {
                                        employee: {
                                            upload_signature: "",
                                        },
                                    }
                                    this.$loading(true)
                                    Expenses.myExpenses(
                                        this.$store.state.portal.profile
                                            .employee.id
                                    ).then((r) => {
                                        this.$loading(true)
                                        this.allExpenses = r.data.results
                                        this.$loading(true)
                                        Expenses.getExpenseEntries(
                                            response.data.results[0].id
                                        ).then((resp) => {
                                            this.$loading(true)
                                            this.entries = []
                                            this.$loading(true)
                                            this.entries = resp.data.results
                                            this.$loading(true)
                                            this.action = false
                                            this.expenses =
                                                response.data.results[0]
                                            this.$loading(true)
                                            this.rerendereExpensesTable = false
                                            this.$nextTick(() => {
                                                this.rerendereExpensesTable = true
                                            })
                                            this.resetLastExpense = false
                                            this.$nextTick(() => {
                                                this.resetLastExpense = true
                                            })
                                            this.$loading(false)
                                        })
                                    })
                                })
                                this.$toast(
                                    {
                                        component: ToastificationContent,
                                        props: {
                                            title: "Success",
                                            icon: "CheckCircleIcon",
                                            text: "Your expense has been successfully submitted! 🥳",
                                            variant: "success",
                                        },
                                    },
                                    {
                                        position: "top-center",
                                    }
                                )
                            })
                            .catch((error) => {
                                this.$loading(false)
                            })
                    } else {
                        this.$loading(false)
                        switch (type) {
                            case "signature":
                                this.$toast(
                                    {
                                        component: ToastificationContent,
                                        props: {
                                            title: "Error: Signature is missing! 🤚",
                                            icon: "XIcon",
                                            text: "Please create or upload your signature.",
                                            variant: "danger",
                                        },
                                    },
                                    {
                                        position: "bottom-center",
                                        timeout: 8000,
                                    }
                                )
                                break
                            case "condition":
                                this.$toast(
                                    {
                                        component: ToastificationContent,
                                        props: {
                                            title: "Please make sure you select I accept statement. 🤚",
                                            icon: "XIcon",
                                            variant: "danger",
                                        },
                                    },
                                    {
                                        position: "bottom-center",
                                        timeout: 8000,
                                    }
                                )
                                break
                            default:
                                this.$toast(
                                    {
                                        component: ToastificationContent,
                                        props: {
                                            title: "Error: Required fields are missing! 🤚",
                                            icon: "XIcon",
                                            text: "Date, category, description, invoice and total are mandatory fields.",
                                            variant: "danger",
                                        },
                                    },
                                    {
                                        position: "bottom-center",
                                        timeout: 8000,
                                    }
                                )
                        }
                    }
                    this.s = false
                }, 500)
            }
        },
        saveExpenses(alert = true, refresh = false, submit = false) {
            this.$loading(true)
            const list = []
            let item = {}
            let valid = true
            const last = this.entries.length
            let i = 0
            let send = true
            this.entries.forEach((element) => {
                this.$loading(true)
                i++
                item = {}
                if ("id" in element) {
                    item.id = element.id
                }
                item.report_id = "report" in element ? element.report.id : ""
                item.report = "report" in element ? element.report.id : ""
                item.date = element.date
                item.category =
                    element.category !== null ? element.category : ""
                item.description = element.description
                item.is_real = element.is_real
                item.invoice = element.invoice

                // Validação de tipo de arquivo
                if (
                    item.invoice &&
                    typeof item.invoice === "object" &&
                    item.invoice.type !== "application/pdf" &&
                    item.invoice.type !== "image/png" &&
                    item.invoice.type !== "image/jpeg" &&
                    item.invoice.type !== "image/jpg"
                ) {
                    
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Error: File format is not supported! 🤚",
                                icon: "XIcon",
                                text: "You can only upload pdf, png, jpg or jpeg files.",
                                variant: "danger",
                            },
                        },
                        {
                            position: "bottom-center",
                            timeout: 8000,
                        }
                    )
                }

                // Validação de integridade de imagens (corrompidas)
                if (
                    item.invoice &&
                    (item.invoice.type === "image/png" ||
                        item.invoice.type === "image/jpeg" ||
                        item.invoice.type === "image/jpg")
                ) {
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const img = new Image()
                        img.onload = () => {
                            // A imagem é válida, prossiga
                        }
                        img.onerror = () => {
                            valid = false
                            this.$toast(
                                {
                                    component: ToastificationContent,
                                    props: {
                                        title: "Error: Corrupted or invalid image file! 🤚",
                                        icon: "XIcon",
                                        text: "The uploaded image appears to be corrupted. Please upload a valid image.",
                                        variant: "danger",
                                    },
                                },
                                {
                                    position: "bottom-center",
                                    timeout: 8000,
                                }
                            )
                            return
                        }
                        img.src = fileReader.result
                    }
                    fileReader.readAsDataURL(item.invoice)
                }

                // Validação de tamanho de arquivo
                if (item.invoice && item.invoice.size / 1024 / 1024 >= 10) {
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Error: File size exceeds 10mb! 🤚",
                                icon: "XIcon",
                                text: "Please try uploading a smaller file.",
                                variant: "danger",
                            },
                        },
                        {
                            position: "bottom-center",
                            timeout: 8000,
                        }
                    )
                }
                item.amount = element.amount.replace(",", "")
                list.push(item)
                this.$loading(true)
                Expenses.updateOrCreateExpensesEntry(item)
                    .then((response) => {
                        this.$loading(true)
                    })
                    .catch(() => {
                        valid = false
                    })
                if (last === i) {
                    if (valid && send && alert) {
                        send = false
                        ;async () =>
                            await Expenses.putExpenses({
                                id: this.expenses.id,
                                date: this.expenses.date,
                                is_empty: this.expenses.is_empty,
                                condition: this.expenses.condition,
                            })
                        this.$toast(
                            {
                                component: ToastificationContent,
                                props: {
                                    title: "Success",
                                    icon: "CheckCircleIcon",
                                    text: "Your entries have been successfully saved! 🥳",
                                    variant: "success",
                                },
                            },
                            {
                                position: "top-center",
                            }
                        )
                    } else if (send && alert) {
                        send = false
                        this.$toast(
                            {
                                component: ToastificationContent,
                                props: {
                                    title: "Error: Required fields are missing! 🤚",
                                    icon: "XIcon",
                                    text: "Date, category, description, invoice and total are mandatory fields.",
                                    variant: "danger",
                                },
                            },
                            {
                                position: "bottom-center",
                                timeout: 8000,
                            }
                        )
                    }
                }
                if (submit) {
                    this.$loading(true)
                }
            })
            this.$loading(true)
            this.$loading(true)
            if (!submit) {
                setTimeout(
                    () => {
                        Expenses.getExpense(this.expenses.id).then(
                            (response) => {
                                this.$loading(true)
                                this.expenses = {
                                    employee: {
                                        upload_signature: "",
                                    },
                                }
                                this.$loading(true)
                                this.expenses = response.data
                                if (submit) {
                                    this.$loading(true)
                                }
                                if (refresh) {
                                    Expenses.getExpenseEntries(
                                        this.expenses.id
                                    ).then((resp) => {
                                        this.$loading(true)
                                        this.entries = []
                                        if (submit) {
                                            this.$loading(true)
                                        }
                                        this.entries = resp.data.results
                                        list.map((element, index) => {
                                            if (
                                                element.id ===
                                                this.entries[index].id
                                            ) {
                                                this.entries[index].date =
                                                    element.date
                                                this.entries[index].day =
                                                    WEEKDAYS[
                                                        new Date(
                                                            `${element.date.split("-")[2]}-${element.date.split("-")[1]}-${element.date.split("-")[0]}`
                                                        ).getDay()
                                                    ]
                                                this.entries[
                                                    index
                                                ].description =
                                                    element.description
                                                this.entries[index].category =
                                                    element.category
                                                this.entries[index].amount =
                                                    element.amount
                                                // this.entries[index].is_real =
                                                //     element.is_real
                                            }
                                        })
                                        if (submit) {
                                            this.$loading(true)
                                        }
                                        this.rerendereExpensesTable = false
                                        // this.action = false
                                        this.$nextTick(() => {
                                            this.rerendereExpensesTable = true
                                        })
                                        if (!submit) {
                                            setTimeout(
                                                () => {
                                                    this.$loading(false)
                                                },
                                                this.entries.length > 30
                                                    ? 12000
                                                    : this.entries.length > 20
                                                      ? 8000
                                                      : this.entries.length > 10
                                                        ? 4000
                                                        : 1000
                                            )
                                        }
                                    })
                                } else if (!submit) {
                                    this.$loading(false)
                                } else {
                                    this.$loading(true)
                                }
                            }
                        )
                    },
                    this.entries.length > 30
                        ? 12000
                        : this.entries.length > 20
                          ? 8000
                          : this.entries.length > 10
                            ? 4000
                            : 1000
                )
            }
        },
        add(item, today = false) {
            this.$loading(true)
            this.$refs.refExpensesTable.$refs.refExpensesTable.items.forEach(
                (item) => {
                    item._showDetails = false
                }
            )
            let line = {}
            if (today) {
                const days = WEEKDAYS
                line = {
                    date: moment(new Date(), "DD-MM-YYYY").format("DD-MM-YYYY"),
                    day: days[new Date().getDay()],
                    report: item.item.report.id,
                    report_id: item.item.report.id,
                }
            } else {
                line = {
                    date: item.item.date,
                    day: item.item.day,
                    report: item.item.report.id,
                    report_id: item.item.report.id,
                }
            }
            Expenses.updateOrCreateExpensesEntry(line).then((response) => {
                Expenses.getEntry(response.data.id).then((resp) => {
                    if (today) {
                        this.entries.push(resp.data)
                    } else {
                        this.entries.splice(item.index + 1, 0, resp.data)
                    }
                    this.$loading(false)
                })
            })
        },
        loadData(date = new Date()) {
            this.$loading(true)
            let dt = (date === null || date === undefined || isNaN(date)) ? new Date() : date; 
            let lastDay = new Date(dt.getFullYear(), dt.getMonth() + 1, 0)
            let employee_id = ""
            if (this.$route.params.id) {
                dt = new Date(
                    this.$route.params.year,
                    Number(this.$route.params.month - 1),
                    1
                )
                lastDay = new Date(
                    this.$route.params.year,
                    Number(this.$route.params.month - 1),
                    0
                )
                employee_id = this.$route.params.id
            }
            this.dateMaxMin.minDate = `${dt.getFullYear()}-${dt.getMonth() - 2}`
            this.dateMaxMin.maxDate = `${dt.getFullYear()}-${dt.getMonth() + 1}-${lastDay.getDate()}`
            Expenses.getExpenses(
                dt.getFullYear(),
                dt.getMonth() + 1,
                employee_id
            )
                .then((response) => {
                    this.$loading(true)
                    if (
                        this.$store.state.portal.profile.employee.expense ||
                        this.$route.params.id ||
                        this.expenseDetail
                    ) {
                        this.$loading(true)
                        if (response.data.results.length > 0) {
                            this.expenses = {
                                employee: {
                                    upload_signature: "",
                                },
                            }
                            this.expenses = response.data.results[0]
                            this.month = new Date(
                                `${this.expenses.date.split("-")[1]}-${
                                    this.expenses.date.split("-")[2]
                                }-${this.expenses.date.split("-")[0]}`
                            )
                            this.getMessages(this.expenses.id)
                            if (
                                this.expenses.status.description !== "Open" &&
                                this.expenses.status.description !==
                                    "Pending changes"
                            ) {
                                this.action = false
                            }
                            Expenses.getExpenseEntries(this.expenses.id).then(
                                (resp) => {
                                    this.entries = []
                                    this.entries = resp.data.results
                                    this.$loading(false)
                                }
                            )
                            this.optionsLastExpenses.chartOptions.plotOptions.pie.donut.labels.total.label =
                                this.expenses.graph.maximum.label
                            this.optionsLastExpenses.chartOptions.plotOptions.pie.donut.labels.total.formatter =
                                () => `${this.expenses.graph.maximum.value}%`
                            this.series = this.expenses.graph.series
                            this.optionsLastExpenses.chartOptions.labels =
                                this.expenses.graph.options
                            this.resetLastExpense = false
                            this.$nextTick(() => {
                                this.resetLastExpense = true
                            })
                            // this.optionsLastExpenses.chartOptions.plotOptions.pie.donut.labels.total.label = this.expenses.graph.maximum.value
                            this.$loading(false)
                        } else {
                            this.notExpenses = false
                            this.$loading(false)
                        }
                    } else {
                        this.myValue = moment(new Date(), "DD-MM-YYYY").format(
                            "DD-MM-YYYY"
                        )
                        Expenses.myExpenses(
                            this.$store.state.portal.profile.employee.id
                        ).then((response) => {
                            this.$loading(false)
                            this.notExpenses = false
                            this.expenses = {
                                employee: {
                                    upload_signature: "",
                                },
                            }
                            this.allExpenses = response?.data?.results
                            this.except = true
                            if (response.data.results.length === 0) {
                                this.$toast(
                                    {
                                        component: ToastificationContent,
                                        props: {
                                            title: "Warning",
                                            icon: "XIcon",
                                            text: "Sorry, we couldn't find any results! 😕",
                                            variant: "warning",
                                        },
                                    },
                                    {
                                        position: "top-center",
                                        timeout: 8000,
                                    }
                                )
                            }
                            this.$loading(false)
                        })
                        this.$loading(false)
                    }
                })
                .catch((error) => {
                    this.$loading(false)
                })
        },
        showDate(value) {
            let date = ""
            date = moment(
                `1-${value.getMonth() + 1}-${value.getFullYear()}`,
                "DD-MM-YYYY"
            ).format("DD-MM-YYYY")
            if (this.myValue === "") {
                this.myValue = moment(date, "DD-MM-YYYY").format("DD-MM-YYYY")
            }
            if (this.$route.params.id) {
                window.location.pathname = `/forms/expenses/${this.$route.params.id}/${
                    value.getMonth() + 1
                }/${value.getFullYear()}`
            } else if (!this.$route.params.id) {
                this.expenses = []
                this.entries = []
                // this.action = false
                // if (typeof value !== 'object') {
                //   this.myValue = moment(date, "DD-MM-YYYY").format("DD-MM-YYYY")
                //   this.loadData(new Date(date.split('-')[1] + '-' + date.split('-')[0] + '-' + date.split('-')[2]))
                // } else {
                //   this.myValue = moment(date, "DD-MM-YYYY").format("DD-MM-YYYY")
                //   this.loadData(new Date(date.split('-')[1] + '-' + date.split('-')[0] + '-' + date.split('-')[2]))
                // }
                this.myValue = moment(date, "DD-MM-YYYY").format("DD-MM-YYYY")
                this.loadData(
                    new Date(
                        `${date.split("-")[1]}-${date.split("-")[0]}-${date.split("-")[2]}`
                    )
                )
                const x = document.getElementsByClassName("mp-index")
                let i
                setTimeout(() => {
                    for (i = 0; i < x.length; i++) {
                        x[i].classList.toggle("mp-index")
                    }
                    if (document.getElementById("monthDate")) {
                        document.getElementById("monthDate").click()
                    } else {
                        this.monthPicker = this.monthPicker !== true
                    }
                }, 100)
            }
        },
        showDetail(item) {
            this.$loading(true)
            setTimeout(() => {
                this.expenseDetail = true
                this.showDate(new Date(item))
                this.$loading(false)
            }, 100)
        },
        previousMonth() {
            let year = new Date(this.expenses.date).getFullYear()
            let month = new Date(this.expenses.date).getMonth()
            if (month <= 0) {
                year--
                month = 12
            }
            if (this.$route.params.id) {
                window.location.pathname = `/forms/expenses/${this.expenses.employee.id}/${month}/${year}`
            } else {
                this.expenses.graph.series = []
                this.entries = []
                // this.action = false;
                this.myValue = moment(
                    new Date(`${year}-${month}-1`),
                    "DD-MM-YYYY"
                ).format("DD-MM-YYYY")
                this.loadData(new Date(`${year}-${month}-1`))
            }
        },
        nextMonth() {
            let year = new Date(this.expenses.date).getFullYear()
            let month = new Date(this.expenses.date).getMonth() + 2
            if (month > 12) {
                year++
                month = 1
            }
            if (this.$route.params.id) {
                window.location.pathname = `/forms/expenses/${this.expenses.employee.id}/${month}/${year}`
            } else {
                this.expenses.graph.series = []
                this.entries = []
                // this.action = false;
                this.myValue = moment(
                    new Date(`${year}-${month}-1`),
                    "DD-MM-YYYY"
                ).format("DD-MM-YYYY")
                this.loadData(new Date(`${year}-${month}-1`))
            }
        },
        setStatusColor() {
            return SettingsColors.setupColor(this.expenses.status.description)
        },
        async download(exportTo = "excel") {
            try {
                this.$loading(true)
                const response =
                    exportTo === "excel"
                        ? await Expenses.downloadEntries(this.expenses.id)
                        : await Expenses.downloadEntries(
                              this.expenses.id,
                              "pdf"
                          )

                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement("a")
                link.href = url
                exportTo === "excel"
                    ? link.setAttribute("download", "expenses.xlsx")
                    : link.setAttribute("download", "expenses.pdf")
                document.body.appendChild(link)
                link.click()
                return
            } catch (error) {
                this.$toast(
                    {
                        component: ToastificationContent,
                        props: {
                            title: "Error: Something wrong! 🤚",
                            icon: "XIcon",
                            text: "Please contact the your manager.",
                            variant: "danger",
                        },
                    },
                    {
                        position: "bottom-center",
                        timeout: 8000,
                    }
                )
            } finally {
                this.$loading(false)
            }
        },
        save(id, signature = "") {
            this.$loading(true)
            const payload = {
                id: this.expenses.id,
                date: this.expenses.date,
                status: id,
            }
            if (signature) {
                payload.attachment = this.file
            }
            Expenses.updateOrCreatePeoplesExpenses(payload).then(() => {
                Expenses.getExpense(this.expenses.id).then((response) => {
                    this.expenses = {
                        employee: {
                            upload_signature: "",
                        },
                    }
                    this.expenses = response.data
                    Expenses.getExpenseEntries(this.expenses.id).then(
                        (resp) => {
                            this.$loading(true)
                            this.entries = []
                            this.action = true
                            this.entries = resp.data.results
                            this.rerendereExpensesTable = false
                            this.$nextTick(() => {
                                this.rerendereExpensesTable = true
                            })
                            this.$loading(false)
                        }
                    )
                })
                // this.loadData()
                switch (id) {
                    case 1:
                        this.$toast(
                            {
                                component: ToastificationContent,
                                props: {
                                    title: "Success",
                                    icon: "CheckCircleIcon",
                                    text: "Employee's expenses has been successfully reopened! 🥳",
                                    variant: "success",
                                },
                            },
                            {
                                position: "top-center",
                            }
                        )
                        break
                    case 3:
                        this.$toast(
                            {
                                component: ToastificationContent,
                                props: {
                                    title: "Success",
                                    icon: "CheckCircleIcon",
                                    text: "Employee's expenses has been successfully approved! 🥳",
                                    variant: "success",
                                },
                            },
                            {
                                position: "top-center",
                            }
                        )
                        break
                    case 4:
                        this.$toast(
                            {
                                component: ToastificationContent,
                                props: {
                                    title: "Success",
                                    icon: "CheckCircleIcon",
                                    text: "Employee's expenses has been successfully declined! 😕",
                                    variant: "success",
                                },
                            },
                            {
                                position: "top-center",
                            }
                        )
                        break
                    default:
                        break
                }
            })
        },
        nudgeAction() {
            Expenses.postNudge({
                id: this.expenses.id,
            })
            this.$toast(
                {
                    component: ToastificationContent,
                    props: {
                        title: "Success",
                        icon: "CheckCircleIcon",
                        text: "Nudge reminder has been successfully sent! 🥳",
                        variant: "success",
                    },
                },
                {
                    position: "top-center",
                }
            )
        },
        getMessages(id) {
            Expenses.getMessage(id).then((response) => {
                this.messages = response.data.results
            })
        },
        submitRequest() {
            Expenses.postMessage({
                expense: this.expenses.id,
                content: this.message,
            })
                .then((response) => {
                    this.message = ""
                    this.getMessages(this.expenses.id)
                    Expenses.putExpenses({
                        id: this.expenses.id,
                        date: this.expenses.date,
                        is_empty: this.expenses.is_empty,
                        condition: this.expenses.condition,
                        status: 7,
                    }).then((resp) => {
                        this.$toast(
                            {
                                component: ToastificationContent,
                                props: {
                                    title: "Success",
                                    icon: "CheckCircleIcon",
                                    text: "Change request has been successfully sent! 🥳",
                                    variant: "success",
                                },
                            },
                            {
                                position: "top-center",
                            }
                        )
                        this.expenses = {
                            employee: {
                                upload_signature: "",
                            },
                        }
                        this.loadData()
                    })
                })
                .catch((error) => {
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Error",
                                icon: "XIcon",
                                text: "Something was wrong. 😕 Please try again.",
                                variant: "danger",
                            },
                        },
                        {
                            position: "bottom-center",
                        }
                    )
                })
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.btn-flat-secondary-icon:hover:not(.disabled):not(:disabled) {
    background-color: rgba(0, 0, 0, 0) !important;
}
.hidden-monthpicker {
    display: none !important;
}
.custom-month-picker {
    background: #fff;
    padding: 10px;
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}
[dir] .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    background: #e9ecef !important;
}
[dir]
    .p-datepicker:not(.p-disabled)
    .p-monthpicker
    .p-monthpicker-month:not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #e9ecef !important;
}
[dir] .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    background: #e9ecef !important;
}
[dir]
    .p-datepicker:not(.p-disabled)
    .p-yearpicker
    .p-yearpicker-year:not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #e9ecef !important;
}
.p-link:focus {
    box-shadow: 0 0 0 0.2rem #e9ecef !important;
}
.p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-year:enabled:hover,
.p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-month:enabled:hover {
    color: #78797b !important;
}
.p-component {
    font-family: "cais-sans", sans-serif !important;
}
.p-multiselect {
    border-radius: 5px !important;
}
[dir] .p-multiselect:not(.p-disabled):hover {
    border-color: #e9ecef !important;
}
.p-inputtext:enabled:hover {
    border-color: #e9ecef !important;
}
.p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2rem #e9ecef !important;
    border-color: #c5c8ca !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: #e9ecef !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #78797b !important;
    background: #78797b !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #78797b !important;
}
[dir] .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none !important;
}
[dir] .p-inputtext {
    border-radius: 5px !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none !important;
}
</style>
